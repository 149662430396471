import React, { useState } from 'react';
import Button from '@material-ui/core/Button'
import { Card, Grid, Box } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Request from '../../lib/Request'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login(props) {

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const isFormValid = () => username.trim() && password.trim()

  const loginUser = (e) => {
    e.preventDefault()
    setErrorMessage("");
    if (!isFormValid()) {
      setErrorMessage("Please provide both username and password");
    } else {
      let opts = {
        method: 'POST',
        url: 'v1/partner/login',
        data: {
          username: username,
          password: password
        }
      }
      Request.Fire(opts)
        .then(data => {
          localStorage.setItem('loggedIn', true)
          localStorage.setItem('authToken', data?.response?.authToken)
          localStorage.setItem('partnerName', data?.response?.partnerName)
          localStorage.setItem('partnerLogo', data?.response?.partnerLogo)
          props.history.push('/overview')
        })
        .catch(err => {
          setErrorMessage("Unable to login. Please make sure you are giving the right credentials");
        })
    }
  }

  const handleUsernameChange = (event) => {
    setUserName(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleSnackBarClose = () => {
    setErrorMessage("");
  }

  return (
    <div style={backgroudStyle}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        open={errorMessage !== ""}
        key={"errorSnackBar"}>
        <Alert severity="error" sx={{ width: '100%' }} onClose={handleSnackBarClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <div style={cardDivStyle}>
        <Card style={cardStyle}>
          <CardContent>
            <Typography variant="overline" noWrap component="div" style={{ color: "#167C80" }}>
              Welcome to Payfura partner dashboard
            </Typography>
            <Box height="30px"></Box>
            <LockOutlinedIcon style={{ color: "#167C80" }} />
            <Typography variant="h5" noWrap component="div" style={{ color: "#167C80" }}>
              Sign In
            </Typography>
            <form onSubmit={loginUser} style={formStyle}>
              <Grid container style={{ justifyContent: "center", marginTop: "40px", paddingLeft: "3em", paddingRight: "3em" }}>
                <TextField
                  style={textStyle}
                  id="username"
                  label="Username"
                  variant='outlined'
                  onChange={handleUsernameChange}
                />
              </Grid>
              <Grid container style={{ justifyContent: "center", marginTop: "40px", paddingLeft: "3em", paddingRight: "3em" }}>
                <TextField
                  style={textStyle}
                  id="password"
                  label="Password"
                  type="password"
                  variant='outlined'
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid container style={{ justifyContent: "center", margin: "auto", marginTop: "40px", }}>
                <Button type="submit" style={buttonStyle} variant="outlined">Login</Button>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const backgroudStyle = {
  backgroundColor: '#87babc',
  padding: '30px',
  minHeight: '1000px',
  minWidth: '1200px'
}

const cardDivStyle = {
  textAlign: 'center',
  margin: "auto",
  marginTop: "100px",
  width: "400px",
  height: "500px"
}

const cardStyle = {
  maxWidth: '400px',
  minHeight: "100%"
}

const formStyle = {
  marginTop: '40px'
}

const textStyle = {
  minWidth: '250px',
}

const buttonStyle = {
  minWidth: '120px',
  color: "#167C80"
}

export default Login
