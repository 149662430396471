class Util {

  static capitalizeFirstLetter(string) {
    if (!string) {
      return '';
    }

    return string = string.charAt(0).toUpperCase() + string.slice(1);
  }

  static parseJSON = (data) => {
    let parsedData = null
    try {
      parsedData = JSON.parse(data)
    } catch (ex) {
      console.error(`Error occured in parsing data ${data}. Error: ${ex}`)
    }
    return parsedData
  }

}

export default Util
