import React, { useEffect } from 'react'
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import DashboardLayout from '../common/DashboardLayout';
import { Link } from 'react-router-dom';

const styles = theme => ({
  chartGrid: {
    padding: "2em",
  },
  linkGrid: {
    padding: "1em",
    paddingLeft: "2em",
  },
  sectionContainer: {
    marginTop: "30px"
  },
  linkText: {
    color: "#0173bf",
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: "bold"
  },
  listItem: {
    color: "#007d82",
    lineHeight: "35px"
  },
  bodyGrid: {
    padding: "1em",
    paddingLeft: "2em"
  }
});

function Analytics(props) {

  const convertToLink = (text, url) => {
    return <Link to={{ pathname: url }} className={props.classes.linkText} target="_blank">{text}</Link>;
  }

  useEffect(() => {
    window.location.href = `https://docs.payfura.com`;
  });

  return (<div></div>
    // <DashboardLayout callingPage="DOCUMENTATION">
    //   <Grid container>
    //     {/* <Grid container height={"40px"} style={{ marginBottom: "20px" }}>
    //     </Grid> */}
    //     <Grid container>
    //       <Grid item className={props.classes.linkGrid}>
    //         <Typography variant="h6" noWrap component="div" color={"#007d82"}>
    //           Quick links
    //         </Typography>
    //       </Grid>
    //     </Grid>
    //     <Grid container>
    //       <Grid item className={props.classes.linkGrid}>
    //         <Typography variant="overline" noWrap component="div" color={"#007d82"}>
    //           <Link to={{ pathname: "https://rampay.notion.site/Rampay-Integration-Steps-9e8fa773020f400c9787b420d8494fe0" }} className={props.classes.linkText} target="_blank">
    //             Ways to customise the widget
    //           </Link>
    //         </Typography>
    //       </Grid>
    //       <Grid item className={props.classes.linkGrid}>
    //         <Typography variant="overline" noWrap component="div" color={"#007d82"}>
    //           <Link to={{ pathname: "https://documenter.getpostman.com/view/1997511/UzBjtocu#intro" }} className={props.classes.linkText} target="_blank">
    //             API Documentation
    //           </Link>
    //         </Typography>
    //       </Grid>
    //       <Grid item className={props.classes.linkGrid}>
    //         <Typography variant="overline" noWrap component="div" color={"#007d82"}>
    //           <Link to={{ pathname: "https://rampay.notion.site/Welcome-to-Rampay-22299f0cf1e94be4867dab59f8d70225" }} className={props.classes.linkText} target="_blank">
    //             More about Rampay
    //           </Link>
    //         </Typography>
    //       </Grid>
    //     </Grid>
    //     <Grid container>
    //       <Grid item className={props.classes.linkGrid}>
    //         <Typography variant="h5" noWrap component="div" color={"#007d82"}>
    //           Introduction to Rampay
    //         </Typography>
    //       </Grid>
    //     </Grid>
    //     <Grid container>
    //       <Grid item className={props.classes.bodyGrid}>
    //         <Typography variant="subline2" noWrap component="div" color={"#007d82"}>
    //           Rampay is on a mission to make fiat to crypto on/off ramp simple, robust and secure for all web3 applications. In order achieve that, we focus heavily on,
    //         </Typography>
    //         <ul style={{ paddingLeft: "2em", marginTop: "5px" }}>
    //           <li className={props.classes.listItem}>
    //             Enabling local payment methods to pay with fiat
    //           </li>
    //           <li className={props.classes.listItem}>
    //             Get the best price for your crypto by integrating multiple exchanges and liquidity providers in the backend
    //           </li>
    //           <li className={props.classes.listItem}>
    //             Have a smooth KYC experience for end users
    //           </li>
    //           <li className={props.classes.listItem}>
    //             Perform industry standard AML checks on users and transactions
    //           </li>
    //         </ul>
    //         <Typography variant="subline2" noWrap component="div" color={"#007d82"}>
    //           For more details on Rampay, you can read {convertToLink("here.", "https://rampay.notion.site/Welcome-to-Rampay-22299f0cf1e94be4867dab59f8d70225")}
    //         </Typography>

    //       </Grid>
    //     </Grid>
    //     <Grid container className={props.classes.sectionContainer}>
    //       <Grid item className={props.classes.linkGrid}>
    //         <Typography variant="h5" noWrap component="div" color={"#007d82"}>
    //           How to integrate
    //         </Typography>
    //       </Grid>
    //     </Grid>
    //     <Grid container>
    //       <Grid item className={props.classes.bodyGrid}>
    //         <Typography variant="h6" noWrap component="div" color={"#007d82"}>
    //           Rampay provides 3 modes of integration:
    //         </Typography>
    //         <ol style={{ paddingLeft: "2em", marginTop: "5px" }}>
    //           <li className={props.classes.listItem}>
    //             <h3>Direct widget integration - Get live quickly in just few lines of code</h3>
    //             Embed our {convertToLink("widget", "https://rampay.io/exchange/")} within your application using an iframe. Customise a lot of things like color theme, default currencies, amount, etc.
    //             <Typography variant="subline2" noWrap component="div" color={"#007d82"}>
    //               For more details on how this integration works, you can follow {convertToLink("this article.", "https://rampay.notion.site/Rampay-Integration-Steps-9e8fa773020f400c9787b420d8494fe0")}
    //               <br />
    //               We'd be happy to chat about adding more customisation specific for you if needed. Just let us know at team@rampay.io
    //             </Typography>
    //           </li>
    //           <li className={props.classes.listItem}>
    //             <h3>End to End API Integration</h3>
    //             Build your own UI and use our APIs to create a user on our end. You can supply user details like address, phone number, name via our APIs and then create an order with this user's context to fix things like wallet address, crypto currency, fiat currency, fiat amount, payment method, etc.
    //             <br />
    //             In this flow, all the widget screens are skipped and user sees a final screen to complete the payment.
    //             <br />
    //             This is how this flow works,
    //             <ul style={{ paddingLeft: "2em", marginTop: "5px" }}>
    //               <li className={props.classes.listItem}>
    //                 Show list of supported {convertToLink("fiat currencies", "https://documenter.getpostman.com/view/1997511/UzBjtocu#1826a451-762d-4135-a337-a1597df71d54")} and {convertToLink("crypto currencies", "https://documenter.getpostman.com/view/1997511/UzBjtocu#5bfddebd-b2b1-4cc3-afab-41d913bdda65")} to your users using the available APIs
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 Get {convertToLink("pricing details", "https://documenter.getpostman.com/view/1997511/UzBjtocu#1465925d-981c-4d5b-918f-bb21f0cc3e5b")} for fiat amount, cryptocurrency and fiat currency.
    //               </li>
    //               <li className={props.classes.listItem}>
    //               {convertToLink("Create a User", "https://documenter.getpostman.com/view/1997511/UzBjtocu#12cd4439-208f-4ffc-8684-726a61180da7")} on our end (using the API secret for auth) in your backend. That will return an User Id.
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 {convertToLink("Create an order", "https://documenter.getpostman.com/view/1997511/UzBjtocu#cb7c4595-76c1-4a01-af95-e415cf5812dd")} (using the API secret for auth) in your backend. That will return an Order Id.
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 Send the Order Id along with your API key in the query params like https://exchange.rampay.io?orderId=fjfnsnsfksmrk454k&apiKey=4u328443km34m2k3
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 Configure webhooks in partner dashboard to get order status updates and get the status of each order using the {convertToLink("GET order API", "https://documenter.getpostman.com/view/1997511/UzBjtocu#6b2cb6fa-c507-4851-87f9-5c4bc450ea30")}.
    //               </li>
    //             </ul>
    //           </li>
    //           <li className={props.classes.listItem}>
    //             <h3>Lightweight API Integration</h3>
    //             Build your own UI and use our APIs to show user crypto prices, create an order on our side to fix things like wallet address, crypto currency, fiat currency, fiat amount, payment method, etc.
    //             <br />
    //             In this flow user can directly submit their information like phone number, name, address in the widget itself.
    //             <br />
    //             This is how this flow works,
    //             <ul style={{ paddingLeft: "2em", marginTop: "5px" }}>
    //               <li className={props.classes.listItem}>
    //                 Show list of supported {convertToLink("fiat currencies", "https://documenter.getpostman.com/view/1997511/UzBjtocu#1826a451-762d-4135-a337-a1597df71d54")} and {convertToLink("crypto currencies", "https://documenter.getpostman.com/view/1997511/UzBjtocu#5bfddebd-b2b1-4cc3-afab-41d913bdda65")} to your users using the available APIs
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 Get {convertToLink("pricing details", "https://documenter.getpostman.com/view/1997511/UzBjtocu#1465925d-981c-4d5b-918f-bb21f0cc3e5b")} for fiat amount, cryptocurrency and fiat currency.
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 {convertToLink("Create an order", "https://documenter.getpostman.com/view/1997511/UzBjtocu#cb7c4595-76c1-4a01-af95-e415cf5812dd")} (using the API secret for auth) in your backend. That will return an Order Id.
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 Send the order Id along with your API key in the query params like https://exchange.rampay.io?orderId=fjfnsnsfksmrk454k&apiKey=4u328443km34m2k3
    //               </li>
    //               <li className={props.classes.listItem}>
    //                 Configure webhooks in partner dashboard to get order status updates and get the status of each order using the {convertToLink("GET order API", "https://documenter.getpostman.com/view/1997511/UzBjtocu#6b2cb6fa-c507-4851-87f9-5c4bc450ea30")}.
    //               </li>
    //             </ul>
    //           </li>
    //         </ol>
    //         <br />
    //         <Typography variant="subline2" component="div" color={"#007d82"}>
    //           We are working on streamlining our API flow to make it less dependent on the widget.
    //           Feel free to discuss with us at team@rampay.io what your ideal flow would look like.
    //         </Typography>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </DashboardLayout>
  );

}

export default withStyles(styles)(Analytics);
