import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from '../lib/Auth'

export default function LoginRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props => (
      Auth.CheckAuth() ? (
        <Redirect to={{ pathname: '/overview' }} />
      ) : (
          <Component {...props} />
        )
    )} />
  )
}
