import Axios from 'axios'
import Config from '../config'
const RL_AUTH_HEADER = "x-rl-partner-auth-token";

const validStatusCodes = [200]
const authenticationErrorStatusCodes = [401]

const AxiosClient = Axios.create({
  baseURL: Config.apiHost,
  responseType: 'json',
  withCredentials: true
})

// Adding interceptor to send auth token with every request
AxiosClient.interceptors.request.use(
  config => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        config.headers[RL_AUTH_HEADER] = authToken;
      }
      return config;
    } catch(e) {
      console.log(e);
      return config;
    }
  },
  error => {
    console.log("error", error);
  }
);

// Adding an interceptor layer in between to handle cases like logout on getting 401.
AxiosClient.interceptors.response.use(response => {
  return response
}, error => {
  if (error && error.response && ~authenticationErrorStatusCodes.indexOf(error.response.status)) {
    localStorage.removeItem('loggedIn')
    localStorage.removeItem('authToken')
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

class Request {

  static Fire (config) {

    const requestPromise = new Promise((resolve, reject) => {
      AxiosClient(config)
      .then(res => {
        if (res && ~validStatusCodes.indexOf(res.status)) {
          resolve(res.data)
        } else {
          reject(new Error('Something went wrong. Please try again later.'))
        }
      })
      .catch(err => {
        reject(err)
      })
    })
    return requestPromise
  }

}

export default Request
