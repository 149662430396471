import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import DashboardLayout from '../common/DashboardLayout';
import Request from '../../lib/Request'
import CustomFormTextField from '../common/CustomFormTextField';
import CustomFormButton from '../common/CustomFormButton';

const styles = theme => ({
  mainGrid: {
    padding: "2em",
  },
  textFieldGrid: {
    width: "70%",
  },
  submitButtonGrid: {
    width: "30%",
    display: "flex"
  },
  subtextGrid: {
    width: "100%",
    display: "flex",
  }
});

function WebhookSettings(props) {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookSecret, setWebhookSecret] = useState("");
  const [subtext, setSubtext] = useState("");

  useEffect(() => {
    let opts = {
      method: 'GET',
      url: '/v1/partner/webhook'
    }
    Request.Fire(opts)
      .then(data => {
        if (data?.response?.webhookUrl) {
          setWebhookUrl(data?.response?.webhookUrl);
        }
        if (data?.response?.webhookSecret) {
          setWebhookSecret(data?.response?.webhookSecret);
        }
      })
      .catch(err => {
      })
  }, []);

  const handleWebhookUrlChange = (e) => {
    setWebhookUrl(e.target?.value);
  }

  const handleWebhookSecretChange = (e) => {
    setWebhookSecret(e.target?.value);
  }

  const handleSubmit = (e) => {
    setSubtext("");
    if (!(webhookUrl && webhookUrl.startsWith("https://"))) {
      setSubtext("Please enter a valid webhook url eg. https://example.com/v1/callback");
      return;
    }
    if (!(webhookSecret && /^[0-9A-Za-z]{10,100}$/.test(webhookSecret))) {
      setSubtext("Please enter only a valid webhook secret. A minimum 10 characters string containing only numbers and english alphabets.");
      return;
    }

    let opts = {
      method: 'POST',
      url: '/v1/partner/webhook',
      data: {
        webhookUrl: webhookUrl,
        webhookSecret: webhookSecret,
      }
    }
    Request.Fire(opts)
      .then(data => {
        if (data?.status === `success` && data?.response?.updatedAt) {
          setSubtext(`Webhook updated successfully on ${data.response.updatedAt}`);
        } else {
          setSubtext(`Webhook was not updated properly. Please try again later or contact team@payfura.com`);
        }
      })
      .catch(err => {
        setSubtext("Error in updating webhook. Please try again later or contact team@payfura.com");
      })
  }

  return (
    <DashboardLayout callingPage="WEBHOOKS">
      <Grid container className={props.classes.mainGrid}>
        <Grid container height={"40px"}>
          <Grid item>
            <Typography variant="subline2" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
              You can set up your webhooks to receive order status updates over a webhook.
            </Typography>
            <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "70px" }} direction="column" spacing={3}>
          <Grid item className={props.classes.textFieldGrid}>
            <CustomFormTextField
              labelText="Webhook URL"
              width="100%"
              value={webhookUrl}
              onChange={handleWebhookUrlChange}
            />
            <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
              We will send a webhook via a POST request on a https secured URL
            </Typography>
          </Grid>
          <Grid item className={props.classes.textFieldGrid}>
            <CustomFormTextField
              labelText="Webhook Secret"
              width="100%"
              value={webhookSecret}
              onChange={handleWebhookSecretChange}
            />
            <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
            This will be present in <b>x-webhook-secret-key</b> header along with the webhook request to validate the auhtenticity of the request.
            </Typography>
            <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
              This should be used server side only. Please don't expose it anywhere on the client side.
            </Typography>
          </Grid>
          <Grid item className={props.classes.submitButtonGrid}>
            <CustomFormButton
              onClick={handleSubmit}
              buttonText="Update Webhook"
              customButtonStyle={{backgroundColor: "#007d82"}}
            />
          </Grid>
          <Grid item className={props.classes.subtextGrid} style={{marginLeft: "10px"}}>
            <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
              {subtext}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default withStyles(styles)(WebhookSettings);
