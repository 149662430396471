import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from '../lib/Auth'

export default function AuthRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props => (
      Auth.CheckAuth() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
    )} />
  )
}
