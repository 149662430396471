import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Grid, Box } from '@mui/material';
import DashboardLayout from '../common/DashboardLayout';
import ShowPassword from '../common/ShowPassword';
import Request from '../../lib/Request'

const styles = theme => ({
  chartGrid: {
    padding: "2em",
  },
  headerText: {
  }
});

function ApiKeys(props) {
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");

  useEffect(() => {
    let opts = {
      method: 'GET',
      url: '/v1/partner/api_key_detail'
    }
    Request.Fire(opts)
      .then(data => {
        if (data?.response?.apiKey) {
          setApiKey(data?.response?.apiKey);
        }
        if (data?.response?.apiSecret) {
          setApiSecret(data?.response?.apiSecret);
        }
      })
      .catch(err => {
      })
  }, [apiKey, apiSecret]);

  return (
    <DashboardLayout callingPage="API_KEYS">
      <Grid container className={props.classes.chartGrid}>
        <Grid container height={"40px"}>
          <Grid item>
            <Typography variant="subline2" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
              You can use the following API Key and API Secret to interact with our APIs.
            </Typography> 
            <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
              Please contact us at team@payfura.com in case you want to change these keys.
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{marginTop: "70px"}}>
          <ShowPassword password={apiKey} labelText="API Key" className={props.classes.headerText} />
          <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
            This can be used on both client side and server side.
          </Typography>
          <Box height="60px"></Box>
          <ShowPassword password={apiSecret} labelText="API Secret" className={props.classes.headerText} />
          <Typography variant="caption" noWrap component="div" color={"#007d82"} className={props.classes.headerText}>
            This should be used server side only. Please don't expose it anywhere on the client side.
          </Typography>
        </Grid>
        
      </Grid>
    </DashboardLayout>
  );
}

export default withStyles(styles)(ApiKeys);
