import { withStyles } from '@mui/styles';
import React from 'react';
import DashboardLayout from '../common/DashboardLayout';
import Summary from '../summary';


const styles = theme => ({
  chartGrid: {
    padding: "2em",
  }
});

function Overview(props) {
  return (
    <DashboardLayout callingPage="OVERVIEW">
      <Summary {...props} />
    </DashboardLayout>
  );
}

export default withStyles(styles)(Overview);
