import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';

function BarChart(props) {
  return (
    <Paper style={{ background: "#eafeff" }}>
      <Grid container>
      <Grid item style={{ margin: "20px" }}>
          <Typography variant="overline" noWrap component="div" color={"#007d82"}>
            {props.captionText}
          </Typography>
        </Grid>
        <Grid item>
          <Chart
            data={props.data}
            height={300}
            width={1000}
          >
            <ArgumentAxis />
            <ValueAxis />
            <BarSeries valueField="value" argumentField="argument" color='#22babf'/>
            <EventTracker />
            <Tooltip />
          </Chart>
        </Grid>
      </Grid>
    </Paper>
  );
}


export default BarChart;