import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Request from '../../lib/Request'
import { Grid } from '@mui/material';
import DashboardLayout from '../common/DashboardLayout';
import BarChart from '../common/BarChart';

const styles = theme => ({
  chartGrid: {
    padding: "1em",
    paddingLeft: 0,
  },
  mainGrid: {
    padding: "2em",
  },
  chartContainer: {
    marginTop: "20px"
  }
});

function Analytics(props) {
  const [dayToUsdVolume, setDayToUsdVolume] = useState([]);
  const [countryToUsdVolume, setCountryToUsdVolume] = useState([]);

  useEffect(() => {
    let opts = {
      method: 'GET',
      url: '/v1/partner/analytics?pageNumber=0&pageSize=1000&duration=30&durationUnit=days'
    }
    Request.Fire(opts)
      .then(data => {
        if (data?.dateToUsdVolumeList?.length) {
          const dateToUsdVolumeList = [];
          data.dateToUsdVolumeList.forEach((item) => {
            dateToUsdVolumeList.push({
              argument: item.time_period,
              value: item.usdVolume,
            });
          });
          setDayToUsdVolume(dateToUsdVolumeList);
        }
        if (data?.countryToUsdVolumeList) {
          const countryToUsdVolumeList = [];
          data.countryToUsdVolumeList.forEach((item) => {
            countryToUsdVolumeList.push({
              argument: item.country,
              value: item.usdVolume,
            });
          });
          setCountryToUsdVolume(countryToUsdVolumeList);
        }
      })
      .catch(err => {
        // alert(lodash.get(err, 'response.data.error', 'Something went wrong. Please try again later.'))
      })
  }, []);

  return (
    <DashboardLayout callingPage="ANALYTICS">
      <Grid container className={props.classes.mainGrid}>
        <Grid container>
          <Grid container>
            <Typography variant="h6" noWrap component="div" color={"#007d82"}>
              Analytics (In Beta)
            </Typography>
          </Grid>
          <Grid container>
            <Typography variant="subline2" noWrap component="div" color={"#007d82"}>
              This section contains detailed analysis for your transactions. We'll be adding more charts soon.
            </Typography>
          </Grid>
          <Grid container>
            <Typography variant="caption" noWrap component="div" color={"#007d82"}>
              All timestamps are in UTC & amounts are in USD.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={props.classes.chartContainer}>
          <Grid item xs={12} sm={12} className={props.classes.chartGrid}>
            <BarChart
              data={dayToUsdVolume}
              captionText="Last 30 days completed orders volume trend (In USD)"
            />
          </Grid>
        </Grid>
        <Grid container className={props.classes.chartContainer}>
          <Grid item xs={12} sm={12} className={props.classes.chartGrid}>
            <BarChart
              data={countryToUsdVolume}
              captionText="Last 30 days completed order volume by country (In USD)"
            />
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );

}

export default withStyles(styles)(Analytics);
