import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import AuthRoute from './router/AuthRoute'
import LoginRoute from './router/LoginRoute'
import Login from './components/login'
import './App.css'
import Overview from './components/overview'
import Analytics from './components/analytics'
import ApiKeys from './components/apikeys'
import Documentation from './components/documentation'
import Webhooks from './components/webhooks'

class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <LoginRoute exact path="/" component={Login} />
          <LoginRoute exact path="/login" component={Login} />          
          <AuthRoute exact path="/overview" component={Overview} />
          <AuthRoute exact path="/analytics" component={Analytics} />
          <AuthRoute exact path="/apikeys" component={ApiKeys} />
          <AuthRoute exact path="/docs" component={Documentation} />
          <AuthRoute exact path="/webhooks" component={Webhooks} />
        </Switch>
      </Router>
    )
  }
}

export default App
